import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import FormDialog from '../../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { Close, Done, PlayArrow, RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../components/AuthAdminProvider';
import moment from 'moment';
import CustomTextField from '../../../components/CustomTextField';
import HermesTypeSelect from '../../../components/HermesTypeSelect';
import TunnelSelect from '../../../components/TunnelSelect';
import EmpresaSelect from '../../../components/EmpresaSelect';
import BtnZabbix from '../../hermes/Zabbix';

const getItem = (storage, key) => {
    const data = localStorage.getItem(storage)
    return data ? Object.keys(JSON.parse(data)).includes(key) ? JSON.parse(data)[key] : null : null
}


export default function Host() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [empresa, setEmpresa] = useState(getItem('hermes', 'empresa') || null)
    const error = useFormError()
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Hermes - Host"

        error.setErrors([])
    }, [])

    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                empresa={empresa}
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Hosts'
                endpoint='/admin/hermes/host'
                options={{
                    customToolbar: () =>
                        <>
                            <EmpresaSelect sx={{
                                width: '400px',
                            }} 
                            value={empresa}
                            handler={(e) => {
                                localStorage.setItem('hermes', JSON.stringify({ empresa: e }))
                                setEmpresa(e)
                            }}
                            />
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('host.create')}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            <BtnZabbix callback={setRefresh(true)} admin empresa={empresa}/>
                           
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'empresa_name',
                        label: 'Empresa',
                    },
                    {
                        name: 'host_name',
                        label: 'Nome',
                    },
                    {
                        name: 'ip',
                        label: 'Ip Address',
                    },
                    {
                        name: "vendor_name",
                        label: "Marca",
                    },
                    {
                        name: 'type_name',
                        label: 'Tipo',
                    },

                    {
                        name: 'status',
                        label: 'Ativo',
                        options: {
                            customBodyRender: (value) => value == 1 ?
                                <Done
                                    sx={{ color: 'green' }}
                                /> : <Close
                                    sx={{ color: 'red' }}
                                />
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('host.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Executar backup">
                                            <IconButton disabled={!auth.hasPerm('backup.create')}
                                                onClick={() => {
                                                    api.get(`/admin/hermes/backup/run/${tableMeta.rowData[0]}`, { host_id: tableMeta.rowData[0] }).then(res => {
                                                        if (res.data.error) {
                                                            notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                                                            return
                                                        }
                                                        if (res.data.warning) {
                                                            notify.setNotify({ open: true, message: res.data.warning, severity: 'warning' })
                                                            return
                                                        }
                                                        notify.setNotify({ open: true, message: "Solicitação enviada com sucesso", severity: 'success' })
                                                    }
                                                    ).catch(e => {
                                                        
                                                        if (e){
                                                            notify.setNotify({ open: true, message: "Muitas solicitações. Aguarde um pouco.", severity: 'warning' })
                                                            return
                                                        }
                                                        // if (e.response.status == 429) {
                                                        //     notify.setNotify({ open: true, message: "Muitas solicitações. Aguarde um pouco.", severity: 'warning' })
                                                        //     return
                                                        // }
                                                    })
                                                }}

                                            >

                                                <PlayArrow />
                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        host_name: '',
        type_vendor: null,
        ip_address: '',
        username: '',
        password: '',
        port: '',
        status: 1,
        empresa: getItem('hermes', 'empresa') || null,


    }
    const [state, setState] = useState(modelState)

    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/hermes/host', id).then(res => {
                setState(res.data.data)

                setIsLoaded(true)
            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id, open])



    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {

        if (id) {
            update('/admin/hermes/host', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        console.log(res.data.errors)
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Host atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/admin/hermes/host/create', state)
                .then(res => {
                    console.log(res)
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Host criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Host</Typography>
                        <IconButton disabled={!auth.hasPerm("host.delete")}
                            sx={{ display: id ? 'block' : 'none' }}
                            onClick={() => {
                                if (confirm('Deseja excluir o Host?')) {
                                    delete_one('/admin/hermes/host', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Host deletado com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    })
                                }
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }} pt={1} gap={1}>
                    <EmpresaSelect
                    handler={(e) => setState({ ...state, empresa: e })} value={state.empresa}
                    disabled={id} 
                    />
                    
                    
                    <CustomTextField

                        error={error.verify('host_name')}
                        helperText={error.msgError('host_name')}
                        label='Nome do Host'
                        value={state?.host_name}
                        name={'host_name'}
                        onChange={handleChange}
                    />
                    <HermesTypeSelect
                        admin
                        fullWidth
                        handler={(type) => setState(prev => ({ ...prev, type }))}
                        value={state?.type}
                        size="small"
                    />
                    <CustomTextField

                        error={error.verify('ip_address')}
                        helperText={error.msgError('ip_address')}
                        label='IP'
                        value={state?.ip_address}
                        name={'ip_address'}
                        onChange={handleChange}
                    />
                    <CustomTextField

                        error={error.verify('username')}
                        helperText={error.msgError('username')}
                        label='Usuário'
                        value={state?.username}
                        name={'username'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        error={error.verify('password')}
                        helperText={error.msgError('password')}
                        label='Senha'
                        value={state?.password}
                        name={'password'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        error={error.verify('enable_password')}
                        helperText={error.msgError('enable_password')}
                        label='Enable Password'
                        value={state?.enable_password}
                        name={'enable_password'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        error={error.verify('port')}
                        helperText={error.msgError('port')}
                        label='Porta'
                        value={state?.port}
                        name={'port'}
                        onChange={handleChange}
                    />
                    <TunnelSelect
                        empresa={state?.empresa}
                        fullWidth
                        admin={true}
                        handler={(tunnel) => setState(prev => ({ ...prev, interface: tunnel }))}
                        value={state?.interface}
                        size="small"
                    />
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                checked={state?.status}
                                onChange={(e) => setState({ ...state, status: e.target.checked ? 1 : 0 })}
                            />
                        }
                            label="Ativo"
                        />
                    </FormGroup>



                </Box>

            </FormDialog>
        </Box>
    )
}
